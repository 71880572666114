"use strict";

if (document.querySelector('.homepage') || document.querySelector('body').getAttribute('id') === 'landing-page') {
  var i = 5000;
  window.addEventListener("scroll", function () {
    console.log('appear here');
    var st = window.pageYOffset;
    document.querySelector(".main").style.height = st + i + "px";
    var blocks = document.querySelectorAll(".block");
    for (var j = 0; j < blocks.length; j++) {
      blocks[j].style.width = st + i * 1000 + "px";
    }
    document.querySelector("#block-1").style.marginLeft = st * -1 + "px";
    document.querySelector("#block-2").style.marginLeft = st / 4 * -1 + "px";
    document.querySelector("#block-3").style.marginLeft = st / 12 * -1 + "px";
    document.querySelector("#block-4").style.marginLeft = st / 8 * -1 + "px";
    var t = document.querySelector("#block-1 > .container:last-child > .content-container:last-child").offsetLeft;
    var s = document.querySelector("#block-2 > .container:last-child > .content-container:last-child").offsetLeft;
    var z = document.querySelector("#block-3 > .container:last-child > .content-container:last-child").offsetLeft;
    var m = document.querySelector("#block-4 > .container:last-child > .content-container:last-child").offsetLeft;
    if (t < 2000) {
      document.querySelector("#block-1").appendChild(document.querySelector("#block-1 > .container:first-child").cloneNode(true));
    }
    if (s < 2000) {
      document.querySelector("#block-2").appendChild(document.querySelector("#block-2 > .container:first-child").cloneNode(true));
    }
    if (z < 2000) {
      document.querySelector("#block-3").appendChild(document.querySelector("#block-3 > .container:first-child").cloneNode(true));
    }
    if (m < 2000) {
      document.querySelector("#block-4").appendChild(document.querySelector("#block-4 > .container:first-child").cloneNode(true));
    }
  });
}

// MENU //
// Add event listener to body element
document.querySelector("body").addEventListener("click", function (event) {
  if (event.target.matches(".menu-button")) {
    // Toggle class on clicked element
    event.target.classList.toggle("menu-on");
    document.body.classList.toggle("active-menu");
  } else if (event.target.matches(".menu-account h3")) {
    // Toggle class on parent element
    event.target.parentElement.classList.toggle("active");
  }
});

// Animated Gallery
// Select all gallery-item elements on the page
var gridItems = document.querySelectorAll('.gallery-item');
// Iterate over the gallery-item elements and set their padding
gridItems.forEach(function (item) {
  // Generate a random padding value (in pixels)
  var padding = Math.floor(Math.random() * 250) + 5;
  // Set the top and bottom padding of the element
  item.style.paddingTop = padding + 'px';
});

// Pop Up
if (document.querySelector('#popup-banner')) {
  var togglePopupBanner = function togglePopupBanner() {
    // Toggle the visibility of the pop-up banner and the overlay
    document.querySelector('#popup-banner').classList.toggle('show');
    document.querySelector('#popup-banner-overlay').classList.toggle('show');
  }; // Add a click event listener to the close button
  if (document.querySelector('.close-popup')) {
    document.querySelector('.close-popup').addEventListener('click', togglePopupBanner);
  }
  // Show the pop-up banner when the page loads
  window.addEventListener('load', togglePopupBanner);
}

// Function to set a cookie
function setCookie(name, value, days) {
  var expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = "expires=".concat(expirationDate.toUTCString());
  document.cookie = "".concat(name, "=").concat(value, ";").concat(expires, ";path=/");
}

// Function to get a cookie
function getCookie(name) {
  var cookieName = "".concat(name, "=");
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var _i = 0; _i < ca.length; _i++) {
    var c = ca[_i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return '';
}

// Call the function fadeInPage()
fadeInPage();