// const element = document.querySelector('.shuffle');
// // get the original HTML of the element
//
// if(element) {
//
//   const originalHTML = element.innerHTML;
//
// // add a hover event listener to the element that triggers the animation
//   element.addEventListener('mouseenter', () => {
//     // split the original HTML into an array of words
//     const words = originalHTML.split(' ');
//
//     // start the shuffle animation
//     const shuffleInterval = setInterval(() => {
//       // shuffle the array of words
//       const shuffledWords = shuffleArray(words);
//
//       // update the element's HTML with the shuffled words
//       element.innerHTML = shuffledWords.join(' ');
//     }, 100); // update the words every 80ms
//
//     // add a mouseleave event listener to the element that stops the animation
//     element.addEventListener('mouseleave', () => {
//       // stop the shuffle animation
//       clearInterval(shuffleInterval);
//
//       // update the element's HTML with the original HTML
//       element.innerHTML = originalHTML;
//     });
//   });
//
//   function shuffleArray(array) {
//     // shuffle the array of words using the Fisher-Yates algorithm
//     for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]];
//     }
//     return array;
//   }
// }
"use strict";