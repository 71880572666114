"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Profile = function Profile() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $confirmPassword = document.querySelector('.js-confirm-password');
  var $newPassword = document.querySelector('.js-new-password');
  var $passwordForm = document.querySelector('.password-form');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Your code here
    if ($passwordForm && $confirmPassword && $newPassword) {
      var isValid = false;
      $passwordForm.addEventListener('submit', function (e) {
        e.preventDefault();
        if ($newPassword.value && $confirmPassword.value && $confirmPassword.value !== $newPassword.value) {
          document.querySelector('.not-same-message').classList.add('is-visible');
        } else {
          document.querySelector('.not-same-message').classList.remove('is-visible');
          isValid = true;
        }
        if (isValid) {
          $passwordForm.submit();
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();